.card_container {
  font-family: "Montserrat", sans-serif;

  display: flex;
  flex-direction: column;
  height: 100%;
  color: white;
  gap: 20px;

  margin: 20px;
  border-radius: 10px;
  box-shadow: 10px 10px 10px rgb(15, 15, 15);
  border: solid 2px rgba(255, 255, 255, 0);
}
.card_container:hover {
  border: solid 2px rgb(214, 214, 214);
}
.gif_container {
  /* position: relative;

  height: 100%; */

  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  justify-self: center;
  align-self: center;
  /* max-width: 800px; */
}
.gif {
  /* position: absolute; */
  height: 100%;
  width: 100%;
  position: absolute;
  inset: 0;
  object-fit: cover;
  border-radius: 0px 0px 10px 10px;
  margin: 0 auto;
}
.card_header {
  font-size: 2rem;
  /* padding: 20px; */
  margin-top: 10px;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.card_header span {
  margin: 0 5%;
}

.instaclone {
  background-color: rgba(0, 0, 0, 0.349);
  background: rgb(131, 58, 180);
  background: linear-gradient(
    90deg,
    rgba(131, 58, 180, 0.2) 0%,
    rgba(253, 29, 29, 0.2) 50%,
    rgba(252, 176, 69, 0.2) 100%
  );
}
.social_humans {
  background-color: rgba(0, 0, 0, 0.349);
  background: rgb(131, 58, 180);
  background: linear-gradient(
    90deg,
    rgba(2, 162, 255, 0.2) 0%,
    rgba(87, 13, 90, 0.2) 50%,
    rgba(69, 133, 252, 0.2) 100%
  );
}

.tnw_clone {
  background-color: rgba(0, 0, 0, 0.349);
  background: rgb(129, 127, 130);
  background: linear-gradient(
    90deg,
    rgba(129, 127, 130, 1) 0%,
    rgba(41, 41, 41, 1) 35%,
    rgba(22, 22, 22, 0.9416141456582633) 50%,
    rgba(38, 38, 38, 1) 63%,
    rgba(129, 127, 130, 1) 100%
  );
}
.card_title {
  white-space: nowrap;
}

@media only screen and (max-width: 575px) {
  .card_container {
    margin: 10px 0px;
  }
}
