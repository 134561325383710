.about_container {
  color: white;
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
  font-family: "Montserrat", sans-serif;
  background-image: linear-gradient(
    0deg,
    rgb(19, 22, 27) 0%,

    rgb(32, 37, 44) 50%,

    rgb(38, 43, 54) 100%
  );
}
.header {
  font-family: "Qwigley", cursive;
  font-size: 12vw;
}
.headerInner {
  display: none;
}
.about_card_container {
  text-align: initial;
  display: grid;
  grid-template-columns: 1fr 4fr;
  margin: 0 auto;
  box-shadow: 10px 10px 10px rgb(15 15 15);
  width: 70%;
}
.about_text {
  padding: 20px;
  font-size: 1.6vw;
  line-height: 1.5;
  background-color: rgb(16, 18, 22);
}
.portrait {
  position: absolute;

  height: 100%;
  width: 100%;
  object-fit: contain;
}
.img_container {
  background-color: rgb(16, 18, 22);
  width: 100%;
  position: relative;
  object-fit: scale-down;

  flex-grow: 1;
}
.list {
  margin: 20px;
}
.socials {
  margin-top: 10px;
  white-space: nowrap;
  display: flex;
  justify-content: space-around;
}

@media only screen and (max-width: 1100px) {
  .about_text {
    font-size: 2vw;
  }
  .about_card_container {
    width: 100%;
  }
}
@media only screen and (max-width: 800px) {
  .header {
    display: none;
  }
  .headerInner {
    display: block;
    font-family: "Qwigley", cursive;
    font-size: 12vw;
  }
  .about_card_container {
    grid-template-columns: 1fr;
  }
  .portrait {
    width: initial;
    position: relative;
    justify-self: center;
    align-self: center;
    order: 1;
  }
  .img_container {
    justify-content: center;
    align-content: center;
    align-items: center;
    display: flex;
  }
  .about_text {
    font-size: 2.8vw;
    order: -1;
  }
  .about_text {
    padding: 20px;
    line-height: 1.5;
  }
}

@media only screen and (max-width: 575px) {
  .about_text {
    font-size: 3.2vw;
  }
}

@media only screen and (max-width: 500px) {
  .about_text {
    font-size: 4.3vw;
  }
}
