@import url("https://fonts.googleapis.com/css2?family=Qwigley&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
html,
body {
  max-width: 100%;

  scroll-snap-type: both mandatory;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
section {
  scroll-snap-align: start;
  text-align: center;
  position: relative;
}
a:hover {
  color: rgb(255, 0, 0);
}
.gitLogo {
  position: fixed;
  color: white;
  width: 100%;
  z-index: 900;
  bottom: 10px;
  left: 10px;
  font-size: 6vw;
}

@media only screen and (max-width: 1100px) {
  .gitLogo {
    font-size: 12vw;
  }
}
