#bg {
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;

  background-image: linear-gradient(
    0deg,
    rgb(19, 22, 27) 0%,

    rgb(32, 37, 44) 50%,

    rgb(38, 43, 54) 100%
  );
}
.matbg {
  object-fit: cover;
}
.container {
  width: 40%;
  position: relative;
  background-color: black;

  text-align: center;
  margin: 20px;
  position: absolute;
  top: 31%;
  left: 45%;
  transform: translate(-50%, -50%);
}

.height {
  position: relative;
}
.text {
  font-family: "Qwigley", cursive;
  font-size: 12vw;
  left: 0px;
  /* width: 100%; */
  color: rgba(255, 255, 255, 0.808);
  position: absolute;
  white-space: nowrap;
}

#arrow,
#arrow:before {
  position: absolute;
  left: 50%;
}

#arrow {
  width: 40px;
  height: 40px;
  top: 40%;
  margin: -20px 0 0 -20px;
  -webkit-transform: rotate(45deg);
  border-left: none;
  border-top: none;
  border-right: 2px #fff solid;
  border-bottom: 2px #fff solid;
  cursor: pointer;
}

#arrow:before {
  content: "";
  width: 20px;
  height: 20px;
  top: 50%;
  margin: -10px 0 0 -10px;
  border-left: none;
  border-top: none;
  border-right: 1px #fff solid;
  border-bottom: 1px #fff solid;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: arrow;
}

@keyframes arrow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(-10px, -10px);
  }
}

@media only screen and (max-width: 600px) {
  #bg {
  }
}

@media only screen and (max-width: 1000px) {
  .matbg {
    object-fit: cover;
  }
  .text {
    font-size: 20vw;
  }

  .container {
    width: 40%;
    position: relative;
    background-color: black;

    text-align: center;
    margin: 20px;
    position: absolute;
    top: 31%;
    left: 30%;
    transform: translate(-50%, -50%);
  }
}

@media only screen and (max-width: 1500px) {
  .text {
    font-size: 18vw;
  }

  .container {
    width: 40%;
    position: relative;
    background-color: black;

    text-align: center;
    margin: 20px;
    position: absolute;
    top: 31%;
    left: 34%;
    transform: translate(-50%, -50%);
  }
}
