#projects {
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
  background-image: linear-gradient(
    0deg,
    rgb(38, 43, 54) 0%,
    rgb(32, 37, 44) 50%,
    rgb(19, 22, 27) 100%
  );
}
.cards_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 80%;
  justify-content: center;
  margin: 0 auto;
  max-width: 1000px;
}

@media only screen and (max-width: 575px) {
  .cards_container {
    width: 100%;
  }
}
